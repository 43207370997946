import {Navigate, Outlet, useLocation} from "react-router";
import useAuth from "../hooks/useAuth"

const ProtectedRoutesLayout = ({UserType}) => {

    const {auth} = useAuth();
    const location = useLocation();

    const UserTypeInline = "";

  return (

      UserType.includes(auth?.role)
    ? <Outlet />
    : auth?.username
        ? <Navigate to="/unauthorized" state={{from: location}} replace/>
        : <Navigate to="/" state={{from: location}} replace/>
    
  )
}

export default ProtectedRoutesLayout