import {Route, Routes} from 'react-router';
import {lazy} from 'react';
import AppLayout from './AppLayout';
import ProtectedRoutesLayout from './components/ProtectedRoutesLayout';
import PersistLogin from "./hooks/PersistLogin";


const Administration = lazy(() => import("./user/pages/administration/Administration"));
const Items = lazy(() => import("./user/pages/administration/Items"));
const UserRolesAndPerm = lazy(() => import("./user/pages/administration/UserRolesAndPerm"));
const Roles = lazy(() => import("./user/pages/administration/auth/Roles"));
const RolesAddEdit = lazy(() => import("./user/pages/administration/auth/RolesAddEdit"));
const Permissions = lazy(() => import("./user/pages/administration/auth/Permissions"));
const PermissionsAddEdit = lazy(() => import("./user/pages/administration/auth/PermissionsAddEdit"));
const Users = lazy(() => import("./user/pages/administration/auth/Users"));
const UsersAddEdit = lazy(() => import("./user/pages/administration/auth/UsersAddEdit"));
const UsersPrivileges = lazy(() => import("./user/pages/administration/auth/UsersPrivileges"));
const Branch = lazy(() => import("./user/pages/administration/items/Apartment"));
const BranchAddEdit = lazy(() => import("./user/pages/administration/items/ApartmentAddEdit"));
const Department = lazy(() => import("./user/pages/administration/items/Houses"));
const DepartmentAddEdit = lazy(() => import("./user/pages/administration/items/HousesAddEdit"));
const Service = lazy(() => import("./user/pages/administration/items/Service"));
const ServiceAddEdit = lazy(() => import("./user/pages/administration/items/ServiceAddEdit"));
const PaymentAccount = lazy(() => import("./user/pages/administration/items/PaymentAccount"));
const PaymentAccountAddEdit = lazy(() => import("./user/pages/administration/items/PaymentAccountAddEdit"));
const StandingCharges = lazy(() => import("./user/pages/administration/items/StandingCharges"));
const StandingChargesAddEdit = lazy(() => import("./user/pages/administration/items/StandingChargesAddEdit"));

const Configuration = lazy(() => import("./user/pages/administration/Configuration"));
const SystemSettings = lazy(() => import("./user/pages/administration/configuration/SystemSettings"));

const CustomerMenu = lazy(() => import("./user/pages/customer/CustomerMenu"));
const Customer = lazy(() => import("./user/pages/customer/Customer"));
const CustomerAddEdit = lazy(() => import("./user/pages/customer/CustomerAddEdit"));

const CustomerReadings = lazy(() => import("./user/pages/customer/readings/CustomerReadings"));
const CustomerAddReading = lazy(() => import("./user/pages/customer/readings/CustomerAddReading"));
const CustomerAddPayment = lazy(() => import("./user/pages/customer/readings/CustomerAddReadingPayment"));
const CustomerReadingInvoice = lazy(() => import("./user/pages/customer/readings/CustomerReadingInvoice"));
const CustomerReadingReceipt = lazy(() => import("./user/pages/customer/readings/CustomerReadingReceipt"));

const CustomerServiceBills = lazy(() => import("./user/pages/customer/service_bills/CustomerServiceBills"));
const CustomerAddServiceBill = lazy(() => import("./user/pages/customer/service_bills/CustomerAddServiceBill"));
const CustomerAddServiceBillPayment = lazy(() => import("./user/pages/customer/service_bills/CustomerAddServiceBillPayment"));
const CustomerServiceBillInvoice = lazy(() => import("./user/pages/customer/service_bills/CustomerServiceBillInvoice"));
const CustomerServiceBillReceipt = lazy(() => import("./user/pages/customer/service_bills/CustomerServiceBillReceipt"));

const CustomerRentBills = lazy(() => import("./user/pages/customer/rent_bills/CustomerRentBills"));
const CustomerAddRentBill = lazy(() => import("./user/pages/customer/rent_bills/CustomerAddRentBill"));
const CustomerAddRentBillPayment = lazy(() => import("./user/pages/customer/rent_bills/CustomerAddRentBillPayment"));
const CustomerRentBillInvoice = lazy(() => import("./user/pages/customer/rent_bills/CustomerRentBillInvoice"));
const CustomerRentBillReceipt = lazy(() => import("./user/pages/customer/rent_bills/CustomerRentBillReceipt"));


const Profile = lazy(() => import("./user/pages/Profile"));

const Register = lazy(() => import("./pages/Register"));
const Login = lazy(() => import("./pages/Login"));

const Unauthorized = lazy(() => import("./pages/Unauthorized"));

function App() {


  return (
    <Routes>
        <Route path='/' element={<AppLayout />}>

          <Route path="register" element={<Register />} />
          <Route index element={<Login />} />
          <Route path="unauthorized" element={<Unauthorized />} />
          

          {/* Artists protected routes */}
          <Route element={<PersistLogin />}>

            <Route element={<ProtectedRoutesLayout UserType={["ROLE_ADMIN"]} />}>

            <Route path="administration" >
              <Route index element={<Administration />} />

              <Route path="auth">
                <Route index element={<UserRolesAndPerm />}/>
                <Route path="roles">
                  <Route index element={<Roles />} />
                  <Route path="add_edit/:id" element={<RolesAddEdit />} />
                  <Route path="add_edit" element={<RolesAddEdit />} />
                </Route>
                <Route path="permissions">
                  <Route index element={<Permissions />} />
                  <Route path="add_edit/:id" element={<PermissionsAddEdit />} />
                  <Route path="add_edit" element={<PermissionsAddEdit />} />
                </Route>
                <Route path="users" >
                  <Route index element={<Users />} />
                  <Route path="add_edit/:id" element={<UsersAddEdit />} />
                  <Route path="add_edit" element={<UsersAddEdit />} />
                  <Route path="privileges/:id" element={<UsersPrivileges />} />
                </Route>
              </Route>

              <Route path="items">
                <Route index element={<Items />}/>

                <Route path="apartments">
                  <Route index element={<Branch />} />
                  <Route path="add_edit/:id" element={<BranchAddEdit />} />
                  <Route path="add_edit" element={<BranchAddEdit />} />
                </Route>

                <Route path="houses">
                  <Route index element={<Department />} />
                  <Route path="add_edit/:id" element={<DepartmentAddEdit />} />
                  <Route path="add_edit" element={<DepartmentAddEdit />} />
                </Route>

                <Route path="service">
                  <Route index element={<Service />} />
                  <Route path="add_edit/:id" element={<ServiceAddEdit />} />
                  <Route path="add_edit" element={<ServiceAddEdit />} />
                </Route>

                <Route path="payment_account">
                  <Route index element={<PaymentAccount />} />
                  <Route path="add_edit/:id" element={<PaymentAccountAddEdit />} />
                  <Route path="add_edit" element={<PaymentAccountAddEdit />} />
                </Route>

                <Route path="standing_charges">
                  <Route index element={<StandingCharges />} />
                  <Route path="add_edit/:id" element={<StandingChargesAddEdit />} />
                  <Route path="add_edit" element={<StandingChargesAddEdit />} />
                </Route>

              </Route>

              <Route path="configuration">
                <Route index element={<Configuration />}/>

                <Route path="system_settings">
                  <Route index element={<SystemSettings />} />
                </Route>

              </Route>

            </Route>

            <Route path="profile" >
                <Route index element={<Profile />} />
            </Route>

           <Route path="customer" >
                <Route index element={<CustomerMenu />} />

                <Route path="list">
                  <Route index element={<Customer />} />
                  <Route path="add_edit/:id" element={<CustomerAddEdit />} />
                  <Route path="add_edit" element={<CustomerAddEdit />} />
                </Route>

                <Route path="add_reading/:id">
                  <Route index element={<CustomerAddReading />} />
                </Route>

                <Route path="add_service_bill/:id">
                  <Route index element={<CustomerAddServiceBill />} />
                </Route>

                <Route path="add_rent_bill/:id">
                  <Route index element={<CustomerAddRentBill />} />
                </Route>

                <Route path="meter_readings">

                  <Route index element={<CustomerReadings />} />

                  <Route path="generate_invoice/:id" element={<CustomerReadingInvoice />} />
                  <Route path="generate_receipt/:id" element={<CustomerReadingReceipt />} />

                  <Route path="add_payment/:id/:payment_id">
                    <Route index element={<CustomerAddPayment />} />
                  </Route>

                </Route>

                <Route path="service_bills">

                  <Route index element={<CustomerServiceBills />} />

                  <Route path="generate_invoice/:id" element={<CustomerServiceBillInvoice />} />
                  <Route path="generate_receipt/:id" element={<CustomerServiceBillReceipt />} />

                  <Route path="add_payment/:id/:payment_id">
                    <Route index element={<CustomerAddServiceBillPayment />} />
                  </Route>

                </Route>

                <Route path="rent_bills">

                  <Route index element={<CustomerRentBills />} />

                  <Route path="generate_invoice/:id" element={<CustomerRentBillInvoice />} />
                  <Route path="generate_receipt/:id" element={<CustomerRentBillReceipt />} />

                  <Route path="add_payment/:id/:payment_id">
                    <Route index element={<CustomerAddRentBillPayment />} />
                  </Route>

                </Route>


            </Route>


          </Route>

            <Route element={<ProtectedRoutesLayout UserType={["ROLE_ADMIN","ROLE_FRONT_DESK"]} />}>

              <Route path="front_desk" >

                  <Route index element={<CustomerMenu />} />

                  <Route path="list">
                    <Route index element={<Customer />} />
                    <Route path="add_edit/:id" element={<CustomerAddEdit />} />
                    <Route path="add_edit" element={<CustomerAddEdit />} />
                  </Route>

                  <Route path="add_reading/:id">
                    <Route index element={<CustomerAddReading />} />
                  </Route>

                  <Route path="add_service_bill/:id">
                    <Route index element={<CustomerAddServiceBill />} />
                  </Route>

                  <Route path="meter_readings">

                    <Route index element={<CustomerReadings />} />

                    <Route path="generate_invoice/:id" element={<CustomerReadingInvoice />} />
                    <Route path="generate_receipt/:id" element={<CustomerReadingReceipt />} />

                    <Route path="add_payment/:id/:payment_id">
                      <Route index element={<CustomerAddPayment />} />
                    </Route>

                  </Route>

                  <Route path="service_bills">

                    <Route index element={<CustomerServiceBills />} />

                    <Route path="generate_invoice/:id" element={<CustomerServiceBillInvoice />} />
                    <Route path="generate_receipt/:id" element={<CustomerServiceBillReceipt />} />

                    <Route path="add_payment/:id/:payment_id">
                      <Route index element={<CustomerAddServiceBillPayment />} />
                    </Route>

                  </Route>

                <Route path="rent_bills">

                  <Route index element={<CustomerRentBills />} />

                  <Route path="generate_invoice/:id" element={<CustomerRentBillInvoice />} />
                  <Route path="generate_receipt/:id" element={<CustomerRentBillReceipt />} />

                  <Route path="add_payment/:id/:payment_id">
                    <Route index element={<CustomerAddRentBillPayment />} />
                  </Route>

                </Route>

                <Route path="profile" >
                  <Route index element={<Profile />} />
                </Route>

              </Route>


            </Route>

            <Route element={<ProtectedRoutesLayout UserType={["ROLE_ADMIN","ROLE_FIELD_AGENT"]} />}>

              <Route path="field_agent" >

                <Route index element={<CustomerMenu />} />

                <Route path="list">
                  <Route index element={<Customer />} />
                  <Route path="add_edit/:id" element={<CustomerAddEdit />} />
                  <Route path="add_edit" element={<CustomerAddEdit />} />
                </Route>

                <Route path="add_reading/:id">
                  <Route index element={<CustomerAddReading />} />
                </Route>

                <Route path="add_service_bill/:id">
                  <Route index element={<CustomerAddServiceBill />} />
                </Route>

                <Route path="meter_readings">

                  <Route index element={<CustomerReadings />} />

                  <Route path="generate_invoice/:id" element={<CustomerReadingInvoice />} />
                  <Route path="generate_receipt/:id" element={<CustomerReadingReceipt />} />

                  <Route path="add_payment/:id/:payment_id">
                    <Route index element={<CustomerAddPayment />} />
                  </Route>

                </Route>

                <Route path="service_bills">

                  <Route index element={<CustomerServiceBills />} />

                  <Route path="generate_invoice/:id" element={<CustomerServiceBillInvoice />} />
                  <Route path="generate_receipt/:id" element={<CustomerServiceBillReceipt />} />

                  <Route path="add_payment/:id/:payment_id">
                    <Route index element={<CustomerAddServiceBillPayment />} />
                  </Route>

                </Route>

                <Route path="rent_bills">

                  <Route index element={<CustomerRentBills />} />

                  <Route path="generate_invoice/:id" element={<CustomerRentBillInvoice />} />
                  <Route path="generate_receipt/:id" element={<CustomerRentBillReceipt />} />

                  <Route path="add_payment/:id/:payment_id">
                    <Route index element={<CustomerAddRentBillPayment />} />
                  </Route>

                </Route>

                <Route path="profile" >
                  <Route index element={<Profile />} />
                </Route>

              </Route>


            </Route>

            <Route element={<ProtectedRoutesLayout UserType={["ROLE_ADMIN","ROLE_TENANT"]} />}>

              <Route path="tenant" >

                <Route index element={<CustomerMenu />} />

                <Route path="list">
                  <Route index element={<Customer />} />
                  <Route path="add_edit/:id" element={<CustomerAddEdit />} />
                  <Route path="add_edit" element={<CustomerAddEdit />} />
                </Route>

                <Route path="add_reading/:id">
                  <Route index element={<CustomerAddReading />} />
                </Route>

                <Route path="add_service_bill/:id">
                  <Route index element={<CustomerAddServiceBill />} />
                </Route>

                <Route path="meter_readings">

                  <Route index element={<CustomerReadings />} />

                  <Route path="generate_invoice/:id" element={<CustomerReadingInvoice />} />
                  <Route path="generate_receipt/:id" element={<CustomerReadingReceipt />} />

                  <Route path="add_payment/:id/:payment_id">
                    <Route index element={<CustomerAddPayment />} />
                  </Route>

                </Route>

                <Route path="service_bills">

                  <Route index element={<CustomerServiceBills />} />

                  <Route path="generate_invoice/:id" element={<CustomerServiceBillInvoice />} />
                  <Route path="generate_receipt/:id" element={<CustomerServiceBillReceipt />} />

                  <Route path="add_payment/:id/:payment_id">
                    <Route index element={<CustomerAddServiceBillPayment />} />
                  </Route>

                </Route>

                <Route path="rent_bills">

                  <Route index element={<CustomerRentBills />} />

                  <Route path="generate_invoice/:id" element={<CustomerRentBillInvoice />} />
                  <Route path="generate_receipt/:id" element={<CustomerRentBillReceipt />} />

                  <Route path="add_payment/:id/:payment_id">
                    <Route index element={<CustomerAddRentBillPayment />} />
                  </Route>

                </Route>

                <Route path="profile" >
                  <Route index element={<Profile />} />
                </Route>

              </Route>


            </Route>

          </Route>
        </Route>
      </Routes>
  )
} 

export default App
