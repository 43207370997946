import {useEffect, useState} from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";
import {Outlet} from "react-router";
import AlertModal from "../components/AlertModal";

const PersistLogin = () => {

    const [isLoading,setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const {auth} = useAuth();

    useEffect(() => {

        const verifyRefreshToken = async () => {

            try {
                await refresh();
            } catch(err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }

        }

        !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false)

    },[])


    return (
        <>
            {
                isLoading ?
                    <AlertModal type={"Warning"} message={"Please wait..."} />
                    : <Outlet />
            }
        </>
    )

}

export default PersistLogin