import React from 'react'
import axios from '../api/axios';
import useAuth from './useAuth'
import useLogout from "./useLogout";

const useRefreshToken = () => {

    const {auth,setAuth} = useAuth();
    const logout = useLogout();

    const refresh = async () => {

        try {

            const response = await axios.get("/auth/refresh_token",
                {
                    withCredentials: true
                }
            );

            setAuth(prev => {
                return {
                    ...prev,
                    username: response?.data?.username,
                    userId: response?.data?.userId,
                    role: response?.data?.role[0].authority,
                    accessToken: response?.data?.token
                }
            });

            // console.log("REFRESH :"+JSON.stringify(response?.data))
            // console.log("REFRESHed AUTH :"+JSON.stringify(auth))

            return response?.data?.token;

        } catch(err) {


            logout();

            //console.log("Error response : "+JSON.stringify(err.response));

        }

    }

  return refresh;
}

export default useRefreshToken