import useAuth from "./useAuth"
import {useLocation, useNavigate} from "react-router-dom";

const useLogout = () => {

    const {setAuth} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    //const from = location.state?.from?.pathname || "/";
    const from = "/";

    const logout = () => {

        setAuth({});
        navigate(from, {replace: true})

    }

    return logout;

}

export default useLogout