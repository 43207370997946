import styled from "styled-components";
import {mobileStyle} from "./responsiveness";

export const deleteButtonStyle = {
    padding: "10px",
    background: "red",
    border: 0,
    borderRadius: "10px",
    cursor: "pointer",
    marginLeft: "5px"
  }

export const style_loader = {
    position: "absolute",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}
  export const editLinkButtonStyle = {
    padding: "10px",
    background: "#fff",
    border: 0,
    borderRadius: "10px",
    textDecoration: "none"
  }

export const redirectPageLinkButtonStyle = {
    padding: "10px",
    background: "#fff",
    border: "1px solid #000",
    borderRadius: "10px",
    textDecoration: "none",
    flex: "1",
    marginRight: "10px"
}
  export const PageInput = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  `;

export const PageInputStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
}

export const PageInputCheckList = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  `;

export const SearchPageInput = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
  `;

  export const PageInputLabel = styled.label`
    text-wrap: wrap;
    margin-right: 5px;
    flex: 1;
    font-weight: bolder;
    color: #5F9CE3;
  `;

  export const PageInputTextField = styled.input`
    padding: 10px;
    margin-top: 6px;
    outline: none;
    border: 1px solid #fff;
    border-radius: 10px;
    color: #fff;
    font-weight: bold;
    background: transparent;
    min-width: 200px;
    width: 90%;
    flex: 2;
    border: 2px solid ${(props) => (props.hasError ? "red" : "#ccc")};
    &:focus {
      background-color: #000000;
    }
  `;

export const BottomMainPanel = styled.div`
  color: #fff;
  width: 98%;
  flex: 3;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
  ${mobileStyle(
    {
        flexDirection: "column",
      width: "100%",
      //overflowX: "auto",
      marginTop: "13%"
    }
)}
`;

export const GlobalFormWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: indigo;
    border: 1px solid #000;
    padding: 20px;
    border-radius: 10px;
    ${mobileStyle(
        {
            position: "relative",
          top: "0px",
          height: "fit-content"
        }
    )}
`;

export const GlobalSearchFormWrapper = styled.div`
    display: flex;
    align-items: center;
    //flex-direction: column;
    justify-content: center;
    background: indigo;
    border: 1px solid #000;
    padding: 20px;
    border-radius: 10px;
    ${mobileStyle(
    {
        position: "relative",
        top: "40px",
        height: "fit-content"
    }
)}
`;

export const Title = styled.h2`
    flex: 1;
    margin-bottom: 10px;
`;

export const Form = styled.form`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  min-width: 200px;
    width: 30vw;
    height: 50vh;
    //flex-wrap: wrap;
    ${mobileStyle(
        {
          width: "100%",
          fontSize: "14px"
        }
    )}
`;

export const SearchForm = styled.form`
    flex: 1;
    display: flex;
    flex-direction: inherit;
    align-items: flex-start;
    justify-content: space-between;
    width: 100vh;
    flex-wrap: wrap;
    ${mobileStyle(
    {
        width: "95vw",
        fontSize: "14px"
    }
)}
`;

export const SupportLinkForm = styled.form`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30vw;
    height: 100%;
    ${mobileStyle(
        {
          width: "100%"
        }
    )}
`;

export const InputText = styled.input`
    padding: 10px;
    margin-top: 10px;
    outline: none;
    border: none;
    border-bottom: 1px solid #fff;
    border-radius: 10px;
    color: #fff;
    font-weight: bold;
    background: transparent;
`;

export const UserTypeSelect = styled.select`
    padding: 10px;
    margin-top: 10px;
    outline: none;
    border: none;
    border-bottom: 1px solid #fff;
    border-radius: 10px;
    color: #fff;
    font-weight: bold;
    background: transparent;
`;


export const UserTypeOption = styled.option``;

export const InputSelect = styled.select`
    padding: 10px;
    margin-top: 10px;
    outline: none;
    border: none;
    //border-bottom: 1px solid #fff;
    border-radius: 10px;
    background: none;
    color: #fff;
    font-size: 20px;
  flex: 2;

  border-bottom: 1px solid ${(props) => (props.hasError ? "red" : "#fff")};
  //&:focus {
  //  background-color: #000000;
  //}
  
      &::selection {
        color: #fff;
      }
  
    ${mobileStyle(
    {
        fontSize: "14px"
    }
)}  
`;

export const InputSelectOption = styled.option`
  color: #000;
`;

//export const InputCheckBox = styled.

export const ProfilePageInputText = styled.input`
    padding: 10px;
    margin-top: 2px;
    outline: none;
    border: none;
    border-bottom: 1px solid #fff;
    border-radius: 10px;
    color: #000;
    font-weight: bold;
    background: transparent;
`;

export const InfoTextValid = styled.p`
    color: green;
    font-weight: bold;
    text-align: center;
    margin-top: 5px;
`;

export const InfoTextInvalid = styled.p`
    color: red;
    font-weight: bold;
    text-align: center;
    margin-top: 5px;
`;

export const Button = styled.button`
    padding: 10px;
    margin-top: 10px;
    background: #000;
    color: #fff;
    font-weight: 200;
    font-size: 30px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    width: 65%;
    ${mobileStyle(
        {
          width: "94%"
        }
    )}
`;

export const LoginText = styled.p`
    font-weight: bold;
    text-align: center;
    margin: 10px 0;
`;

export const linkStyle = {
    textDecoration: "none",
    color: "green"
};

export const Panels = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ErrorPanel = styled.div`
    color: red;
    font-weight: bold;
  font-size: 10px;
    text-align: center;
    margin-top: 5px;
`;

export const SuccessPanel = styled.div`
    color: green;
    font-weight: bold;
    text-align: center;
    margin-top: 5px;
`;
export const LoaderPanel = styled.div`

`;

export const MenuSidebar = styled.div`
    flex: 2;
    width: 270px;
    background: #fff;
    position: fixed;
    display: block;
  z-index: 12;

    @media screen and (max-width: 790px){
      background: "limeseagreen";
      top: 50px;
      left: 0;
      z-index: 12;
      width: 100%;
      height: "100vh";
      display: ${props => props.display}
    }
`;

export const SubMenu = styled.div`
  flex: 1;
  min-width: 200px;
  max-width: 300px;
  max-height: 200px;
  width: 70%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3px;
  background: #5F9CE3;
  border-radius: 10px;
  cursor: pointer;
  margin: 10px; 
  ${mobileStyle(
    {
        marginLeft: "2%",
        marginRight: "2%",
        minWidth: "180px",
        maxWidth: "790px",
        width: "95%",
        minHeight: "400px"
    }
)}

  &:hover {
    box-shadow: 1px 2px 10px 10px rgba(0,0,0,0.5);
  }
`;

export const SubMenuImage = styled.img`
      width: 60%;
      height: 80%;
    ${mobileStyle(
    {
        width: "50%",
        height: "70%"
    }
)}
`;

export const SubMenuTitle = styled.h2`
    font-weight: bold;
    font-size: 15px;
    ${mobileStyle(
    {
        fontSize: "20px",
        marginTop: "25px"
    }
)}
`

export const SubMenuLink = {
    textDecoration: "none",
    width: "100%",
    height: "100%",
    textAlign: "center",
    color: "#fff"
}

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    position: relative;
    ${mobileStyle(
    {
        width: "100vw",
        overflowX: "auto"
    }
)}
`;

export const MainPanel = styled.div`
    flex: 7;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    margin-left: 270px;
    ${mobileStyle(
    {
        flex: "3",
        marginLeft: "0px",
        height: "fit-content",
        overflow: "auto"
    }
)}
`;

export const DeleteButton = styled.button`
    display: none;
    `;