import {Box, Modal} from "@mui/material"
import LoaderImage from "../assets/loader.svg"
import CheckedImage from "../assets/checked.png"
import CrossImage from "../assets/cross.png"
import styled from "styled-components"
import {Link, useLocation, useNavigate} from "react-router-dom"
import {Button} from "./AppStyle";

const style = {
    position: "absolute",
    top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: "background.paper"
}

const style_loader = {
    position: "absolute",
    top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}

const loaderActionTextStyle = {
    color: "#fff"
}

const infoImageStyle = {
    width: "50%"
}

const ActionButton = styled.button`
    cursor: pointer;
    width: 90%;
    padding: 10px;
    background: indigo;
    color: #fff;
    border: 0;
    border-radius: 10px;
`;

const LinkButton = {
    cursor: "pointer",
    width: "90%",
    padding: "10px",
    background: "indigo",
    color: "#fff",
    border: "0",
    borderRadius: "10px"
}


const AlertModal = ({type,message,handleInitialiseMappings,handleDelete,isModalVisible,setIsModalVisible,redirectUrl}) => {



    const handleCloseModal = () => {
        setIsModalVisible(false);
    }

    const handleRedirectLink = () => {

        if(redirectUrl !== undefined) {
            //navigate(from, {replace: true})
        } else {
            setIsModalVisible(false);
        }

    }

    return (

        type == "Warning" ?
                <Modal open={isModalVisible}>
                    <Box sx={style}>
                    <div class="modal-dialog modal-confirm">
                <div class="modal-content">
                    <div class="modal-header flex-column">
                        
                            <img src={CrossImage} style={infoImageStyle} />
                        						
                        <h4 class="modal-title w-80">Are you sure?</h4>	
                        <button type="button" class="close" onClick={handleCloseModal}>&times;</button>
                    </div>

                    <div class="modal-body">
                        <p>{message}</p>
                    </div>
                    <div class="modal-footer justify-content-center">
                        <button type="button" class="btn btn-secondary" onClick={handleCloseModal}>Cancel</button>
                        <button type="button" class="btn btn-danger" onClick={handleDelete}>Delete</button>
                    </div>
                </div>
            </div>
                    </Box>
                </Modal>

        : type == "InitialiseWarning" ?
                <Modal open={isModalVisible}>
                    <Box sx={style}>
                        <div class="modal-dialog modal-confirm">
                            <div class="modal-content">
                                <div class="modal-header flex-column">

                                    <img src={CrossImage} style={infoImageStyle} />

                                    <h4 class="modal-title w-80">Are you sure?</h4>
                                    <button type="button" class="close" onClick={handleCloseModal}>&times;</button>
                                </div>

                                <div class="modal-body">
                                    <p>{message}</p>
                                </div>
                                <div class="modal-footer justify-content-center">
                                    <button type="button" class="btn btn-secondary" onClick={handleCloseModal}>Cancel</button>
                                    <button type="button" class="btn btn-danger" onClick={handleInitialiseMappings}>Initialise</button>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Modal>

        : type == "Error" ?
        
                <Modal open={isModalVisible}>
                <Box sx={style_loader}>
                            <div class="modal-dialog modal-confirm">
                            <div class="modal-content">
                            <div class="modal-header flex-column">
                                <img src={CrossImage} style={infoImageStyle} />	
                                <h2 style={loaderActionTextStyle}>{message}</h2>
                                <ActionButton class="btn btn-success" onClick={handleCloseModal}>Ok</ActionButton>				
                            </div>

                        </div>
                    </div>
                </Box>
            </Modal>

        : type == "Loading" ?

            <Modal open={isModalVisible}>
                <Box sx={style_loader}>
                            <div class="modal-dialog modal-confirm">
                            <div class="modal-content">
                            <div class="modal-header flex-column">
                                <img src={LoaderImage} />	
                                <h2 style={loaderActionTextStyle}>{message}</h2>				
                            </div>

                        </div>
                    </div>
                </Box>
            </Modal>

        :

            <Modal open={isModalVisible}>
                <Box sx={style_loader}>
                            <div class="modal-dialog modal-confirm">
                            <div class="modal-content">
                            <div class="modal-header flex-column">
                                <img src={CheckedImage} style={infoImageStyle} />	
                                <h2 style={loaderActionTextStyle}>{message}</h2>
                                {/*<Button onClick={redirectUrl}>Ok</Button>*/}
                                <Link to={redirectUrl} replace style={LinkButton} onClick={handleCloseModal}>Ok</Link>
                            </div>

                        </div>
                    </div>
                </Box>
            </Modal>

    )

}

export default AlertModal