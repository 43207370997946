import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import App from './App';
import {ArtProvider} from "./provider/ArtProvider";
import AlertModal from './components/AlertModal';
import eruda from "eruda";

//eruda.init();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense fallback={<AlertModal type={"Loading"} isModalVisible={true} />} >
      <BrowserRouter>
        <ArtProvider>
            <Routes>
              <Route path="/*" element={<App />}/>
            </Routes>
        </ArtProvider>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>
);
