import axios from "axios";

//const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
const BASE_URL = "http://157.245.152.32:8080/api/v1";
//const BASE_URL = "http://localhost:8080/api/v1";
//const BASE_URL = "https://399d-102-211-145-31.ngrok-free.app/api/v1";

export default axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-Type":"application/json"
    }
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-Type":"application/json"
    },
    withCredentials: true
});