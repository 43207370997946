import {Outlet} from "react-router"
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";


const AppLayout = () => {
  return (
    <div className="app">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Outlet />
        </LocalizationProvider>
    </div>
  )
}

export default AppLayout