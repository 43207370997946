import {createContext, useState} from "react";

export const ArtContext = createContext({});

export const ArtProvider = ({children}) => {

    const [auth,setAuth] = useState({});
    const [sidebarDisplay,setSidebarDisplay] = useState("none");

    return (
        <ArtContext.Provider value={{auth,setAuth,sidebarDisplay,setSidebarDisplay}}>
            {children}
        </ArtContext.Provider>
    )

}